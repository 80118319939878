<template>
    <router-view></router-view>
</template>

<script>
    import { mapActions } from 'vuex'
    import store from '@/vuex/store.js'

    export default {
        created() {
            this.getNoticesCount()
            this.getNotices()
            this.getMessagesCount()
            this.getMessages()
            this.getSetting()
            this.getUser()
            this.startSignalR()
        },
        methods: {
            ...mapActions([
                'getNoticesCount',
                'getNotices',
                'getMessagesCount',
                'getMessages',
                'getSetting',
                'getUser'
            ]),
        },
        computed: {
        },
        store
    }
</script>

<style>
</style>
